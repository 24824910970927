/**
 *Index style
 */
.wh100 {
  width: 100%;
  height: 100%;
}

.pd100 {
  padding: 0;
  margin: 0;
}

html,
body {
  overflow: hidden;
  @extend .wh100;
}

body {
  position: relative;
}

#root {
  @extend .pd100;
}